import React from 'react';
import './Refund.css';

const Refund = () => {
  return (
    <div className="refund-container">
      <h1 className='refund-head'>Refunds and Cancellations</h1>
      <p>
        Thank you for choosing ContentOcean Infotech Private Limited for our digital services. We strive to provide high-quality services and ensure customer satisfaction. However, due to the nature of our digital services, we have specific policies regarding refunds and cancellations. Please review the following points:
      </p>

      <section className="refund-section">
        <h2>No Refunds for Digital Services</h2>
        <p>
          As a provider of digital services, we regretfully inform you that we are unable to offer refunds once the service has been provided or delivered. This policy applies to all our digital services, including but not limited to website development, content creation, graphic design, and digital marketing. We encourage you to thoroughly review our services, specifications, and terms before making a purchase.
        </p>
      </section>

      <section className="refund-section">
        <h2>Cancellation Policy</h2>
        <p>
          While refunds may not be available, we understand that circumstances may change, and you may need to cancel your order or project. If you wish to cancel a service or project, please contact us as soon as possible at <a href="mailto:business@shreedigimarketing.in">business@shreedigimarketing.in</a> with your cancellation request. We will assess the status of the project and provide guidance regarding cancellation possibilities.
        </p>
      </section>

      <section className="refund-section">
        <h2>Exceptional Circumstances</h2>
        <p>
          In exceptional cases, where there are demonstrable errors or failures on our part that significantly affect the outcome or quality of the service provided, we will review the situation on a case-by-case basis. If we find that we are responsible for such errors or failures, we may consider offering a partial refund or an appropriate solution, as determined by our discretion.
        </p>
      </section>

      <section className="refund-section">
        <h2>Communication and Support</h2>
        <p>
          If you have any questions or concerns regarding our refund or cancellation policy, please feel free to reach out to us at <a href="mailto:business@shreedigimarketing.in">business@shreedigimarketing.in</a>. We will be happy to assist you and address any queries you may have.
        </p>
      </section>

      <section className="refund-section">
        <h2>Amendments to the Policy</h2>
        <p>
          We reserve the right to update or modify our refund and cancellation policy at any time without prior notice. Any changes to this policy will be effective immediately upon posting on our website. It is your responsibility to review the policy periodically to stay informed about any updates.
        </p>
        <p>
          Please note that this Refunds/Cancellations policy applies solely to the services provided by ContentOcean Infotech Private Limited and does not cover any third-party services or products.
        </p>
      </section>

      <p className="thank-you">
        Thank you for your understanding and cooperation. We value your business and strive to deliver exceptional digital services.
      </p>
    </div>
  );
};

export default Refund;
