import React, { useState } from 'react';
import { Carousel, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import video1 from './videos/Business Background Video.mp4'
import iconic from './videos/Iconic Online Marketing Background.mp4'
import { Link } from 'react-router-dom'

function Home() {
  const [name, setName] = useState('');
  const [business, setBusiness] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmitt = (event) => {
    event.preventDefault(); // Prevent the default form submission

    const whatsappMessage = `Name: ${name}\nBusiness: ${business}\nService: ${service}\n Message: ${message}\n`;
    const whatsappURL = `https://wa.me/7410726107?text=${encodeURIComponent(whatsappMessage)}`;

    window.location.href = whatsappURL; // Redirect to WhatsApp
  };

  return (
    <>

      <div className="container-fluid position-relative p-0">
        <Carousel id="header-carousel" className="carousel-fade" data-bs-ride="carousel">
          <Carousel.Item>
            <video className="w-100" autoPlay muted loop>
              <source src={iconic} type="video/mp4" />
            </video>
            <Carousel.Caption className="carousel-caption">
              <div className="p-3" style={{ maxWidth: '900px' }}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Creative & Innovative</h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn">Creative & Innovative Digital Solution</h1>
                {/* <a href="/contact" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
        <a href="/contact" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <video className="w-100" autoPlay muted loop>
              <source src={video1} type="video/mp4" />

            </video>

          </Carousel.Item>
        </Carousel>
      </div>

      {/* Full Screen Search Start */}
      <Modal id="searchModal" fullscreen>
        <Modal.Content style={{ background: 'rgba(9, 30, 62, 0.7)' }}>
          <Modal.Header className="border-0">
            <Button variant="bg-white" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></Button>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center">
            <div className="input-group" style={{ maxWidth: '600px' }}>
              <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
              <Button className="btn btn-primary px-4"><i className="bi bi-search"></i></Button>
            </div>
          </Modal.Body>
        </Modal.Content>

      </Modal>

      <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-users text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Happy Clients</h5>
                  <h1 className="text-white mb-0" data-toggle="counter-up">100+</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.2s">
              <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">Projects Done</h5>
                  <h1 className="mb-0" data-toggle="counter-up">80+</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.4s">
              <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Win Awards</h5>
                  <h1 className="text-white mb-0" data-toggle="counter-up">5</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                <h1 className="mb-0">The Best IT Solution</h1>
              </div>
              <p className="mb-4">
                Based in Jalgaon and founded in 2022, Shree Digital Marketing Agency specializes in 360-degree digital marketing, providing tailored solutions to help brands scale up.

                Our expertise extends to content creation, ensuring impactful and engaging material for your audience. At Shree, we are dedicated to transparency, integrity, and delivering measurable results, making us your trusted partner in the dynamic world of digital marketing.

                Whether you're a startup looking to establish your online presence or an established brand seeking to expand your reach, we have the strategies and solutions to help you achieve your goals.

                Our team of experienced professionals is committed to understanding your unique needs and developing customized strategies to drive growth and success for your business.

                Partner with Shree Digital Marketing Agency today and let us help you unlock the full potential of your online presence.            </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Award Winning</h5>
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Professional Staff</h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>24/7 Support</h5>
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Fair Prices</h5>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">91 7410 726 107</h4>
                </div>
              </div>
              <a href="/contact" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</a>
            </div>
            <div className="col-lg-5" style={{ minHeight: '500px' }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpg" style={{ objectFit: 'cover' }} alt="About Us" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
            <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
            <h1 className="mb-0">We Are Here to Grow Your Business Exponentially</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                    <i className="fa fa-cubes text-white"></i>
                  </div>
                  <h4>Best In Industry</h4>
                  <p className="mb-0">Leading with cutting-edge strategies and exceptional results. Your brand shines in the digital world.</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                    <i className="fa fa-award text-white"></i>
                  </div>
                  <h4>Award Winning</h4>
                  <p className="mb-0">Celebrated for excellence and innovation with industry accolades showcasing our success.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.4" style={{ minHeight: '350px' }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded" data-wow-delay="0.1s" src="assets/img/feature.jpg" style={{ objectFit: 'cover' }} alt="Feature" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                    <i className="fa fa-users-cog text-white"></i>
                  </div>
                  <h4>Professional Staff</h4>
                  <p className="mb-0">Expert team delivering top-tier marketing solutions tailored to your needs.</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                    <i className="fa fa-phone-alt text-white"></i>
                  </div>
                  <h4>24/7 Support</h4>
                  <p className="mb-0">Always available to ensure your campaigns run flawlessly, anytime, day or night.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
            <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
            <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.2s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fas fa-chart-line text-white"></i>
                </div>
                <h4 className="mb-3">Social Media Marketing</h4>
                <p className="m-0">Partner with a leading digital marketing agency to increase brand awareness, reach your target audience & grow your business online.</p>
                <Link className="btn btn-lg btn-primary rounded" to="/contact">
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fas fa-envelope-open text-white"></i>
                </div>
                <h4 className="mb-3">Email Marketing</h4>
                <p className="m-0">Reach out to potential customers through personalized email campaigns.</p>
                <Link className="btn btn-lg btn-primary rounded" to="/contact">
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.5s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-code text-white"></i>
                </div>
                <h4 className="mb-3">Website Designing</h4>
                <p className="m-0">Create visually appealing and user-friendly websites tailored to your business needs.</p>
                <Link className="btn btn-lg btn-primary rounded" to="/contact">
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.2s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fab fa-android text-white"></i>
                </div>
                <h4 className="mb-3">Apps Development</h4>
                <p className="m-0">Transform your business with our expert app development services, delivering custom, high-performance mobile solutions tailored to your needs</p>
                <Link className="btn btn-lg btn-primary rounded" href="/contact">
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-search text-white"></i>
                </div>
                <h4 className="mb-3">SEO Optimization</h4>
                <p className="m-0">Work with a top search engine optimization company to achieve better search engine ranking, earn more traffic and generate greater revenue.</p>
                <Link className="btn btn-lg btn-primary rounded" to="/contact">
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.5s">
              <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                <h3 className="text-white mb-3">Call Us For Quote</h3>
                <p className="text-white mb-3">Shree Digital Marketing Agency specializes in 360-degree digital marketing, providing tailored solutions to help brands scale up.</p>
                <h2 className="text-white mb-0">+91 7410726107</h2>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">Request A Quote</h5>
                <h1 className="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-4"><i className="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-4"><i className="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support</h5>
                </div>
              </div>
              <p className="mb-4">
                Ready to elevate your digital presence? Simply provide your details, and our experts will craft a personalized strategy tailored to your needs. No strings attached, just insightful solutions to boost your success.

              </p>
              <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.3s">
                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 7410726107</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                <form onSubmit={handleSubmitt}>
                  <div className="row g-3">
                    <div className="col-xl-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Your Name"
                        style={{ height: '55px' }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Your Business"
                        style={{ height: '55px' }}
                        value={business}
                        onChange={(e) => setBusiness(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <select
                        className="form-select bg-light border-0"
                        style={{ height: '55px' }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                      >
                        <option value="">Select A Service</option>
                        <option value="Website Designing">Website Designing</option>
                        <option value="Social Media Marketing">Social Media Marketing</option>
                        <option value="App Development">App Development</option>
                        <option value="Whatsapp Marketing">Whatsapp Marketing</option>
                        <option value="Google Ads">Google Ads</option>
                        <option value="Graphic Designing">Graphic Designing</option>
                        <option value="Linkdin Marketing">Linkdin Marketing</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0"
                        rows="3"
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-dark w-100 py-3" type="submit">Request A Quote</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>





    </>
  );
}

export default Home;
