import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Header from './Header';
import Footer from './Footer';
import Services from './Services';
import Contact from './Contact';
import Prices from './Prices';
import PrivacyPolicy from './PrivacyPolicy';
import Refund from './Refund';
import Terms from './Terms';

const routes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: < About /> },
  { path: '/services', element: <Services /> },
  { path: '/prices', element: <Prices /> },
  { path: '/contact', element: <Contact /> },
  { path:'/privacy', element:<PrivacyPolicy/>},
  { path:'/refund', element:<Refund/>},
  { path: '/terms', element:<Terms/>},
]

const App = () => {
  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

  }, [location])

  return (
    <>
      <Header />
      <Routes>
        {routes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Routes>
      <Footer />
    </>
  )
}

export default App